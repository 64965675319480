
import Vue from "vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { api } from "@/api/api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { StoreState } from "@/store/store.state.interface";
import { ApiUpsertRoomDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";

const getRoomInitialData = (): ApiUpsertRoomDto => ({
  name: "",
  isActive: true,
});

export default defineComponent({
  name: "RoomModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    areaId: {
      type: Number,
    },
    locationId: {
      type: Number,
    },
    roomId: {
      type: Number,
    },
  },
  setup({ modalType, areaId, locationId, roomId }, context) {
    const store = useStore<StoreState>();
    const route = useRoute();

    const room = ref<ApiUpsertRoomDto>();

    onMounted(async () => {
      if (!areaId || !locationId || !roomId) {
        room.value = getRoomInitialData();
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        room.value = (await api.area.getRoomAsync(areaId, locationId, roomId)).data;
      });
    });

    const handleSubmit = async () =>
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();

        if (!isValid || !room.value) {
          return;
        }

        if (modalType === ModalType.Add) {
          await api.area.createRoomAsync(+route.params.areaId, +route.params.locationId, room.value);
          openNotification(store, NotificationItemType.Success, "Rommet er lagt til");
        } else if (modalType === ModalType.Edit) {
          if (!areaId || !locationId || !roomId) {
            return;
          }
          await api.area.updateRoomAsync(areaId, locationId, roomId, room.value);
          openNotification(store, NotificationItemType.Success, "Endringene er lagret");
        }
        context.emit("close");
        context.emit("update");
      });

    return {
      handleSubmit,
      room,
      validateNotEmpty,
      readonly: computed(() => modalType === ModalType.Display),
    };
  },
});
