var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.room ? _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.handleSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Romnavn",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.room.name,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "name", $$v);
      },
      expression: "room.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Etasje"
    },
    model: {
      value: _vm.room.floorNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "floorNumber", $$v);
      },
      expression: "room.floorNumber"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Beskrivelse"
    },
    model: {
      value: _vm.room.description,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "description", $$v);
      },
      expression: "room.description"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Anbefalt kapasitet",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.room.recommendedCapacity,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "recommendedCapacity", $$v);
      },
      expression: "room.recommendedCapacity"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Kapasitet",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.room.capacity,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "capacity", $$v);
      },
      expression: "room.capacity"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "inset": "",
      "label": "Aktiv"
    },
    model: {
      value: _vm.room.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.room, "isActive", $$v);
      },
      expression: "room.isActive"
    }
  })], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }