
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import BaseTable from "@/components/shared/table/BaseTable.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { VSwitch } from "vuetify/lib";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { useRoute } from "@/shared/useHelpers";
import { api } from "@/api/api";
import router from "@/router/router";
import RoomModal from "@/components/administration/location/RoomModal.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ApiGetLocationDto, ApiGetRoomDto } from "@/api/generated/Api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { useOpenModal, getInitialModalData } from "@/fragments/modal/useOpenModal";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";

export default defineComponent({
  name: "RoomPage",
  components: { RoomModal, BaseTableFiltered, BaseTable, BaseModal, BaseLayout },

  setup() {
    const route = useRoute();
    const search = ref("");
    const location = ref<ApiGetLocationDto>();
    const rooms = ref<ApiGetRoomDto[]>();
    const modalData = ref(getInitialModalData());
    // TODO consider refactoring away from BaseTableFiltered
    const expandedItems = ref<ApiGetLocationDto[]>([]);
    const { areaId, locationId } = route.params;

    const subheaders = [
      { text: "ID", value: "id" },
      { text: "Handlinger", value: "actions" },
      { text: "Romnavn", value: "name" },
      { text: "Kapasitet", value: "capacity" },
      {
        text: "Beskrivelse",
        value: "description",
      },
      { text: "Anbefalt kapasitet", value: "recommendedCapacity" },
      { text: "Etasje", value: "floorNumber" },
      { text: "Aktiv", value: "isActive" },
    ];
    const mainheaders = [
      { text: "Gateadresse", value: "street" },
      { text: "Detaljer", value: "details" },
      { text: "Postnummer", value: "postalCode" },
      {
        text: "Poststed",
        value: "postalArea",
      },
      { text: "Status", value: "isActive" },
    ];

    const filter = computed(() => [
      {
        component: VSwitch,
        value: "isActive",
        staticClass: "mx-3",
        default: true,
        attrs: {
          label: "Vis inaktive",
          inset: true,
        },
        apply: (value: any, model: any) => model || value,
      },
    ]);

    const loadData = async () =>
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        location.value = (await api.area.getLocationAsync(+areaId, +locationId)).data;
        rooms.value = location.value.rooms || [];
        expandedItems.value = [location.value];
      });

    onMounted(() => {
      loadData();
    });

    const openNewRoomModal = useOpenModal(ModalType.Add, "rom", modalData);
    const openEditRoomModal = useOpenModal(ModalType.Edit, "rom", modalData);

    const navigateBack = () => {
      router.push({
        name: "locations",
        params: {
          areaId,
        },
      });
    };

    return {
      openNewRoomModal,
      openEditRoomModal,
      modalData,
      navigateBack,
      rooms,
      location,
      search,
      subheaders,
      mainheaders,
      filter,
      expandedItems,
      loadData,
      locationsLink: () => useNavigateBack(AdminRouteNames.Locations),
    };
  },
});
