import { ModalType } from "@/shared/enums/modalTypeEnum";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { Ref } from "@vue/composition-api";

export const useOpenModal = (modalType: ModalType, elementName: string, modalData: Ref<ModalBaseData>) => {
  const openModal = (
    selectedItem?: any,
    selectedItemName?: string,
    additionalProperties: Record<string, unknown> = {}
  ) => {
    const getHeadline = () => {
      if (modalType === ModalType.Add) {
        return `Legg til ${elementName}`;
      }
      if (modalType === ModalType.Edit) {
        return `Rediger ${selectedItemName || elementName}`;
      }
      if (modalType === ModalType.Delete) {
        return `Slett ${selectedItemName || elementName}`;
      }
      if (modalType === ModalType.Display) {
        return selectedItemName || elementName;
      }
      if (modalType === ModalType.Unspecified) {
        return selectedItemName || elementName;
      }
      if (modalType === ModalType.Change) {
        return `Endrer ${selectedItemName || elementName}`;
      }
    };

    modalData.value = {
      ...modalData.value, // Prevent overwriting existing values
      modalHeadline: (getHeadline() || modalData.value.modalHeadline) ?? "Ukjent",
      modalType,
      showModal: true,
      additionalProperties,
    };

    if (modalType === ModalType.Add) {
      modalData.value.existingItem = undefined;
      modalData.value.existingItemId = undefined;
    } else {
      modalData.value.existingItem = selectedItem;
      modalData.value.existingItemId = selectedItem?.id;
    }
  };

  return openModal;
};

export const getInitialModalData = (): ModalBaseData => ({
  modalHeadline: "",
  modalType: ModalType.Unspecified,
  showModal: false,
  existingItem: undefined,
  existingItemId: undefined,
  additionalProperties: {},
  defaultValues: {},
});
