var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    class: {
      'pa-0': _vm.noContainer
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": 2
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "dark": "",
      "color": "primary"
    },
    scopedSlots: _vm._u([_vm.displayTabs ? {
      key: "extension",
      fn: function fn() {
        return [_vm._t("tabs")];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-toolbar-title', {
    class: {
      'align-self-end': !!_vm.$slots.subheader
    },
    attrs: {
      "data-cy": "courseToolbarTitle"
    }
  }, [_vm._t("title"), _vm.$slots.subheader ? _c('v-subheader', [_vm._t("subheader")], 2) : _vm._e()], 2), _c('v-spacer'), _vm._t("actions")], 2), _vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }