var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('RoomModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "areaId": _vm.modalData.additionalProperties.areaId,
      "locationId": _vm.modalData.additionalProperties.locationId,
      "roomId": _vm.modalData.additionalProperties.roomId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "update": _vm.loadData
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.locationsLink
          }
        }, [_vm._v("mdi-arrow-left")]), _vm.location ? _c('span', [_vm._v("Rom i " + _vm._s(_vm.location.street))]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openNewRoomModal"
          },
          on: {
            "click": _vm.openNewRoomModal
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til rom på lokasjon ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTable', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.mainheaders,
      "items": [_vm.location],
      "expanded": _vm.expandedItems,
      "hidePaginator": true
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expandedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function fn(_ref) {
        var headers = _ref.headers;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('BaseTableFiltered', {
          attrs: {
            "headers": _vm.subheaders,
            "items": _vm.rooms,
            "search": _vm.search,
            "filter": _vm.filter
          },
          scopedSlots: _vm._u([{
            key: "item.isActive",
            fn: function fn(_ref2) {
              var value = _ref2.value;
              return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
            }
          }, {
            key: "actions",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref4) {
                    var on = _ref4.on,
                        attrs = _ref4.attrs;
                    return [_c('v-icon', _vm._g(_vm._b({
                      staticClass: "mr-2",
                      on: {
                        "click": function click($event) {
                          return _vm.openEditRoomModal(item, item.name, {
                            areaId: _vm.location.areaId,
                            locationId: _vm.location.id,
                            roomId: item.id
                          });
                        }
                      }
                    }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v("Rediger")])])];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref6) {
        _objectDestructuringEmpty(_ref6);

        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": _vm.navigateBack
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-arrow-left ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Tilbake")])])];
      }
    }], null, true)
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }